import * as React from "react"

import Text from "../components/Text"
import CenteredLayout from "../components/CenteredLayout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <CenteredLayout px="24" py="100">
    <Text size="h1">404: Not Found</Text>
    <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
  </CenteredLayout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
