import styled from "styled-components"
import { margins, paddings, hasMargins, hasPaddings } from "../util/style"

const CenteredLayout = styled.div`
  margin: ${props => margins(props)};
  padding: ${props => paddings(props)};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 1279px) {
    ${props => {
      if (props.ipad) {
        return `
          ${hasMargins(props.ipad) ? `margin: ${margins(props.ipad)};` : ""}
          ${hasPaddings(props.ipad) ? `padding: ${paddings(props.ipad)};` : ""}
        `
      }
    }}
  }

  @media only screen and (max-width: 767px) {
    ${props => {
      if (props.mobile) {
        return `
          ${hasMargins(props.mobile) ? `margin: ${margins(props.mobile)};` : ""}
          ${
            hasPaddings(props.mobile)
              ? `padding: ${paddings(props.mobile)};`
              : ""
          }
          ${
            props.mobile.align
              ? `align-items: ${
                  props.mobile.align === "left" ? "flex-start" : "flex-end"
                }`
              : ""
          }
        `
      }
    }}
  }
`

export default CenteredLayout
